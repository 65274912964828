<template>
    <div class="reset-password">
        <div class="container">
            <div v-if="this.status == 0" class="content">
                <div class="skeleton-loading h1"></div>
                <div class="skeleton-loading p"></div>
            </div>
            <div v-if="this.status == 1" class="content">
                <h3>修改密碼</h3>
                <p>{{this.reset ? '修改密碼成功！' : '請輸入您的新密碼'}}</p>
                <p v-if="this.reset">將於 {{ toHome }} 秒後回到首頁。</p>
                <InputUnit v-if="!this.reset" title="新密碼" type="password" placeholder="至少8個字元" v-model="resetData.password" />
                <InputUnit v-if="!this.reset" title="確認密碼" type="password" placeholder="請再次輸入您的密碼" v-model="resetData.repeatPassword" />
                <button v-if="!this.reset" @click="resetPassword()">送出</button>
            </div>
            <div v-if="this.status == 2" class="content">
                <img class="failed" src="../assets/images/failed.svg" alt="">
                <h3>驗證失敗</h3>
                <p>{{ this.errMsg }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import {apiResetPasswordVerify, apiResetPassword} from '@/apis/account.js';
import md5 from 'js-md5'
import InputUnit from '@/components/InputUnit';

export default {
    name: 'ResetPassword',
    components: {
        InputUnit
    },
    data() {
        return{
            status: 0, // 0: waiting, 1: true, 2: false
            toHome: 5,
            errMsg: "",
            reset: false,
            resetData: {
                password: "",
                repeatPassword: ""
            }
        }
    },
    methods: {
        backToHome(){
            setInterval(() => {
                this.toHome -= 1;
                if(this.toHome == 0)window.location = '/';
            }, 1000)
        },
        resetPasswordVerify(){
            apiResetPasswordVerify({uid: this.$route.params.uid, vid: this.$route.params.vid}).then(res => {
                if(res.data.status){
                    this.status = 1
                }else{
                    this.status = 2
                    this.errMsg = res.data.msg
                }
            })
        },
        resetPassword(){
            if(this.resetData.password.length < 8){
                this.$toast.error("密碼至少需8字元", {
                    timeout: 2000
                });
            }else if(this.resetData.password != this.resetData.repeatPassword){
                this.$toast.error("請確認您輸入兩次相同的密碼", {
                    timeout: 2000
                });
            }else{
                apiResetPassword({
                    u_id: this.$route.params.uid,
                    password: md5(this.resetData.password + process.env.VUE_APP_MD5_SALT),
                    token: this.$route.params.vid
                }).then(res => {
                    if(res.data.status){
                        this.reset = true
                        this.backToHome()
                    }else{
                        this.$toast.error(res.data.msg, {
                            timeout: 2000
                        });
                    }
                })
            }
        }
    },
    mounted() {
        this.resetPasswordVerify()
    },
}
</script>